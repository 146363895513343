// @flow


export function getProductNumsBySiteNumber(siteNumber: number) {
  switch (siteNumber) {
    case 80:
      return [ 243, 274, ];
    case 10:
    case 20:
      return [ 273, 274, ];
    case 85:
      return [ 239, ];
    default:
      return [];
  }
}


export function getSiteAliasBySiteNumber(siteNumber: number) {
  switch (siteNumber) {
    case 85:
      return 'HDC';
    case 10:
    case 20:
      return 'TM';
    default:
      return 'HTZ';
  }
}
